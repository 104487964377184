// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cardiac-js": () => import("./../../../src/pages/cardiac.js" /* webpackChunkName: "component---src-pages-cardiac-js" */),
  "component---src-pages-clearviews-js": () => import("./../../../src/pages/clearviews.js" /* webpackChunkName: "component---src-pages-clearviews-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cookie-policy-js": () => import("./../../../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-emgcomfort-js": () => import("./../../../src/pages/emgcomfort.js" /* webpackChunkName: "component---src-pages-emgcomfort-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-radiolucent-leadwires-js": () => import("./../../../src/pages/radiolucent-leadwires.js" /* webpackChunkName: "component---src-pages-radiolucent-leadwires-js" */),
  "component---src-pages-site-map-js": () => import("./../../../src/pages/site-map.js" /* webpackChunkName: "component---src-pages-site-map-js" */),
  "component---src-pages-source-js": () => import("./../../../src/pages/source.js" /* webpackChunkName: "component---src-pages-source-js" */),
  "component---src-pages-standardization-js": () => import("./../../../src/pages/standardization.js" /* webpackChunkName: "component---src-pages-standardization-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-thank-you-clearviews-js": () => import("./../../../src/pages/thank-you-clearviews.js" /* webpackChunkName: "component---src-pages-thank-you-clearviews-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */)
}

